<template>
  <Modal ref="modal">
    <Form @submit="save">
      <div class="sm:flex">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          <svg
            class="h-6 w-6 text-green-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z" />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-grow">
          <h3
            id="modal-headline"
            class="text-lg leading-6 font-medium text-gray-900"
          >
            FAQ bearbeiten
          </h3>
          <div>
            <BasicInput
              id="question"
              v-model="faqClone.question"     
              type="text"
              name="question"
              input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              label="Frage"
              label-class="block text-sm font-medium text-gray-700"
              rules="required"
            />
            <div>
              <BasicInput
                id="answer"
                v-model="faqClone.answer"
                type="text"
                name="answer"
                input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                label="Antwort"
                label-class="mt-4 block text-sm font-medium text-gray-700"
                rules="required"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4">
        <button
          class="text-red-500 text-sm font-medium"
          type="button"
          @click="deleteFAQ"
        >
          Löschen
        </button>
        
        <button
          type="button"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          @click="close()"
        >
          Abbrechen
        </button>
        <button
          type="submit"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Speichern
        </button>
      </div>
    </Form>
  </Modal>
</template>
<script>
import feathers from '@/feathers-client'
import BasicInput from '../BasicInput.vue'
import { Form } from 'vee-validate'
import Modal from '../Modal.vue'
export default {
  components: { BasicInput, Form, Modal },
  emits: ['close'],
  data() {
    return {
      faqClone: {
        question: '',
        answer: ''
      }
    }
  },
  methods: {
    showModal(faq){
      this.faqClone = faq
      this.$refs.modal.show()
    },
    close(){
      this.$emit('close')
      this.$refs.modal.hide()
    },
    async save() {
      try {
        await feathers.service('faq').patch(this.faqClone._id, this.faqClone)
        this.close()
      } catch (error) {
        console.error(error)
      }
    },
    async deleteFAQ(){
      try{
        await this.$store.dispatch('faq/remove', [this.faqClone._id])
        this.close()
      }catch(error){
        console.error(error)
      }
    }
  }
}
</script>
