<template>
  <div v-if="customer != null">
    <layout-header>
      <template #default>
        Kunden
      </template>
      <template #actions>
        <!-- <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-orange-300 rounded-md shadow-sm text-sm font-medium text-orange-700 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            @click="lockCustomer()"
          >
            Sperren
          </button> -->
        <button
          type="button"
          class="ml-3 inline-flex items-center px-4 py-2 border border-red-600 rounded-md shadow-sm text-sm font-medium text-red-600 bg-white hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          @click="deleteCustomer()"
        >
          Löschen
        </button>
      </template>
    </layout-header>
    <div class="p-4">
      <dl class="pt-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 mb-3">
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Name
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ customer.firstname }} {{ customer.lastname }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Email
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ customer.email }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Adresse
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ customer.address.street }} {{ customer.address.streetNumber }} <br>
            {{ customer.address.zip }} {{ customer.address.city }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Rolle
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ roles[customer.role].human }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Erstellt am
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ dayjs(customer.createdAt).format('DD.MM.YYYY HH:mm') }}
          </dd>
        </div>
      </dl>
      <button
        type="button"
        class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 float-right"
        @click="$router.go(-1)"
      >
        Zurück
      </button>
    </div>
  </div>
</template>

<script>
import roles from '@/resources/enums/roles.js'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import store from '@/store'
import LayoutHeader from '../../components/LayoutHeader.vue'

export default {
  components: { LayoutHeader },
  // async beforeRouteEnter (to, from, next) {
  //   await store.dispatch('users/get', to.params.userId)
  //   await next(vm => vm.customer = vm.$store.getters['users/get'](to.params.userId).clone())
  //},
  data () {
    return {
      roles,
      dayjs,
      customer: null,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData(){
      this.customer = await store.dispatch('users/get', this.$route.params.userId)
      if (this.customer.address == null) {
        this.customer.address = {
          street: '',
          streetNumber: '',
          zip: '',
          city: ''
        }
      }
    },
    async deleteCustomer() {
      try {
        await this.$store.dispatch('users/remove',[this.customer._id])
        this.$router.push({ name: 'CustomerList' })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
