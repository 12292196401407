<template>
  <Modal ref="modal">
    <Form @submit="save">
      <div class="sm:flex">
        <div class="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          <svg
            class="h-6 w-6 text-green-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z" />
          </svg>
        </div>
        <div class="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
          <h3
            id="modal-headline"
            class="text-lg leading-6 font-medium text-gray-900"
          >
            Kunden einladen
          </h3>
          <div class="grid grid-cols-2 gap-4">
            <BasicInput
              id="firstname"
              v-model="invitation.firstname"
              type="text"
              input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              label="Vorname"
              label-class="block text-sm font-medium text-gray-700"
              rules="required"
            />
            <BasicInput
              id="lastname"
              v-model="invitation.lastname"
              type="text"
              input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              label="Nachname"
              label-class="block text-sm font-medium text-gray-700"
              rules="required"
            />
            <BasicInput
              id="email"
              v-model="invitation.email"
              type="email"
              class="col-span-2"
              input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full block sm:text-sm border-gray-300 rounded-md"
              label="E-Mail"
              label-class="block text-sm font-medium text-gray-700"
              rules="required|email-pattern"
            />
            <div class="col-span-2">
              <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4 mb-1">
                CSV hochladen
              </h3>
              <small class="text-sm leading-6 font-medium">
                Beispiel Datei 
                <a
                  class="text-indigo-600 "
                  href="/api/invitations.csv"
                  download
                >herunterladen</a>
              </small>
              <div class="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div class="space-y-1 text-center">
                  <svg
                    class="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="flex text-sm text-gray-600">
                    <label
                      for="file-upload"
                      class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span>CSV Datei hochladen</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        class="hidden"
                        accept=".csv"
                        @change="upload"
                      >
                    </label>
                    <p class="pl-1">
                      oder hineinziehen
                    </p>
                  </div>
                  <p class="text-xs text-gray-500">
                    .csv
                  </p>
                </div>
              </div>
              <small class="text-green-500">{{ message }}</small>
              <p class="text-red-500">
                {{ errorMessage }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4">
        <input
          type="submit"
          value="Speichern"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
        <button
          type="button"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          @click="reset()"
        >
          Abbrechen
        </button>
      </div>
    </Form>
  </Modal>
</template>
<script>
import { models } from '@/feathers-client'
import Modal from '../Modal.vue'
import BasicInput from '../BasicInput.vue'
import {Form} from 'vee-validate'
import axios from 'axios'

export default {
  components: { BasicInput, Form, Modal },
  emits: ['fetchData'],
  data() {
    return {
      invitation: null,
      host: process.env.VUE_APP_API_HOST,
      message: '',
      errorMessage: ''
    }
  },
  computed: {
    jwt () {
      return this.$store.state.auth.accessToken
    },
    tenantId(){
      return this.$route.params.tenantId
    }
  },
  methods: {
    reset() {
      this.invitation = new models.api.Invitations({
        email: '',
        firstname: '',
        lastname: '',
        tenantId: this.tenantId
      })
      this.message = '',
      this.errorMessage = '',
      this.$refs.modal.hide()
    },
    showModal() {
      this.reset()
      this.$refs.modal.show()
    },
    async save() {
      try {
        await this.invitation.save()
        this.$emit('fetchData')
        this.$refs.modal.hide()
      } catch (error) {
        console.error(error)
        this.errorMessage = error.message
      }
    },
    async upload(event){
      try {
        await axios.put(`${this.host}api/import-invitations?jwt=${this.jwt}&tenantId=${this.tenantId}`, event.target.files[0])
        this.message = event.target.files[0].name + ' war Erfolgreich'
      } catch (error) {
        console.error(error)
        this.errorMessage = error.response.data
      }
      
      this.$emit('fetchData')
    }
  }
}
</script>
