import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class Contract extends BaseModel {
  constructor (data, options) {
    super(data, options)
  }


}

// Contract.instanceDefaults = function() {
//   return {
//     parentContractId: null,
//     contractCompanyId: null,
//     contractKindId: null,
//     userId: null,
//     tenantId: null,
//     comment: null,
//     startDate: null,
//     endDate: null,
//     number: null,
//     paymentType: null,
//     price: null,
//     isParentContract: null,
//     data: {},
//     status: null,
//     origin: null,
//     attachments: []
//   }
// }

Contract.modelName = 'Contract'

const servicePath = 'contracts'
const servicePlugin = makeServicePlugin({
  idField: '_id',
  Model: Contract,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
