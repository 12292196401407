<template>
  <div v-if="contractCompany">
    <alert-snackbar
      v-if="messageVisible"
      :color="messageColor"
      :message="message"
    />
    <layout-header>
      <template #default>
        Gesellschaft - {{ contractCompany.name }} {{ contractCompany.vuNumber }}
      </template>
      <template
        v-if="contractCompany.tenantId && contractCompany.contractCompanyId"
        #actions
      >
        <button
          type="button"
          class="mx-2 inline-flex items-center px-4 py-2 border border-red-600 rounded-md shadow-sm text-sm font-medium text-red-600 bg-white hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          @click="deleteContractCompany()"
        >
          Löschen
        </button>
      </template>
    </layout-header>
    <Form
      v-if="contractCompany"
      @submit="save"
    >
      <div class="bg-white py-6 px-4 sm:p-6">
        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <BasicInput
              id="name"
              v-model="contractCompany.name"
              disabled
              type="text"
              name="name"
              input-class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              label="Name"
              label-class="block text-sm font-medium text-gray-700"
              rules="required"
            />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <BasicInput
              id="vuNumber"
              v-model="contractCompany.vuNumber"
              disabled
              type="text"
              name="vuNumber"
              input-class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              label="VU Nummer"
              label-class="block text-sm font-medium text-gray-700"
            />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <div class="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div class="space-y-1 text-center">
                <svg
                  class="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="flex text-sm text-gray-600">
                  <label
                    for="file-upload"
                    class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>Datei Hochladen</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      class="sr-only"
                      @change="upload"
                    >
                  </label>
                  <p class="pl-1">
                    oder drag and drop
                  </p>
                </div>
                <p class="text-xs text-gray-500">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
          </div>

          <div
            v-if="contractCompany.logo"
            class="col-span-4 sm:col-span-2"
          >
            <img :src="contractCompany.logo.downloadUrl">
          </div>

          <div class="col-span-full">
            <div class="pt-5">
              <div class="flex justify-end">
                <button
                  type="button"
                  class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="$router.go(-1)"
                >
                  Zurück
                </button>
                <input
                  type="submit"
                  value="Speichern"
                  class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import axios from 'axios'
import { models } from '@/feathers-client'
import store from '@/store'
import feathers from '@/feathers-client'

import LayoutHeader from '../../components/LayoutHeader.vue'
import AlertSnackbar from '../../components/AlertSnackbar.vue'
import BasicInput from '../../components/BasicInput.vue'
import {Form} from 'vee-validate'

export default {
  components: { LayoutHeader, AlertSnackbar, BasicInput, Form },
  async beforeRouteEnter (to, from, next) {
    await store.dispatch('contract-companies/get', to.params.contractCompanyId)
    await next(vm => vm.contractCompany = vm.$store.getters['contract-companies/get'](to.params.contractCompanyId).clone())
  },
  data () {
    return {
      newContractCompany: new models.api.ContractCompanies(),
      contractCompany: null,
      message: '',
      messageColor: 'red',
      messageVisible: false
    }
  },
  computed: {
    user(){
      return this.$store.getters['auth/user']
    },
    tenantId() {
      return this.$route.params.tenantId || this.user.tenantId
    },
  },
  methods: {
    async deleteContractCompany() {
      try {
        await this.$store.dispatch('contract-companies/remove',[this.contractCompany._id])
        this.$router.push({ name: 'ContractCompaniesUserList' })
      } catch (error) {
        console.error(error)
      }
    },
    async save () {
      try {
        if (!this.contractCompany.tenantId && !this.contractCompany.contractCompanyId) {
          const newContractCompany = new models.api.ContractCompanies({
            ...this.contractCompany,
            _id: undefined,
            contractCompanyId: this.contractCompany._id,
            tenantId: this.tenantId
          })
          await newContractCompany.save()
          this.contractCompany = newContractCompany
          this.$router.push({ name: 'ContractCompaniesUserDetail', params: { contractCompanyId: newContractCompany._id } })
        } else {
          await this.contractCompany.save()
        }
        this.showMessage('Erfolgreich gespeichert', 'green')
      } catch (error) {
        console.error(error)
        this.showMessage(error.message || error, 'red')
      }
    },
    showMessage(message, messageColor) {
      this.message = message
      this.messageColor = messageColor
      if(!this.messageVisible) setTimeout(() => this.messageVisible = false, 5000)
      this.messageVisible = true
    },
    async upload(event) {
      try {
        const toUploadFile = event.target.files[0]

        const file = await feathers.service('file').create({
          name: toUploadFile.name,
          mimeType: toUploadFile.type
        })
        await axios.put(file.uploadUrl, toUploadFile)

        this.contractCompany.logo = file
        this.contractCompany.logoId = file._id
      } catch(error) {
        console.error(error)
        this.showMessage(error.message || error, 'red')
      }
    }
  }
}
</script>
