<template>
  <div
    v-if="editor"
    v-click-outside="onClickOutside"
    tabindex="0"
    @click="showMenu = true"
  >
    <label
      for="container"
      class="block text-sm font-medium text-gray-700"
    >
      {{ label }}
    </label>
    <div
      id="container"
      class="border border-gray-300 divide-y divide-gray-300 rounded-md"
    >
      <div
        v-if="showMenu"
        class="ml-2 my-1.5"
      >
        <button
          type="button"
          class="rounded px-2 py-0.5"
          :class="editor.isActive('bold')? activeButton : disabledButton"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <FontAwesomeIcon
            size="sm"
            :icon="faBold"
          />
        </button>
        <button
          type="button"
          class="ml-1 rounded px-2 py-0.5"
          :class="editor.isActive('italic')? activeButton : disabledButton"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <FontAwesomeIcon
            size="sm"
            :icon="faItalic"
          />
        </button>
        <button
          type="button"
          class="ml-1 rounded px-2 py-0.5"
          :class="editor.isActive('heading', {level: 5})? activeButton : disabledButton"
          @click="editor.chain().toggleHeading({ level: 5}).focus().run()"
        >
          <FontAwesomeIcon
            size="sm"
            :icon="faH1"
          />
        </button>
        <button
          type="button"
          class="ml-1 rounded px-2 py-0.5"
          :class="editor.isActive('heading', {level: 6})? activeButton : disabledButton"
          @click="editor.chain().toggleHeading({ level: 6}).focus().run()"
        >
          <FontAwesomeIcon
            size="sm"
            :icon="faH2"
          />
        </button>
      </div>
      <EditorContent :editor="editor" />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import { defaultExtensions } from '@tiptap/starter-kit'
import Heading from '@tiptap/extension-heading'
import vClickOutside from '../helpers/clickOutside.js'

import { faBold, faItalic, faH1, faH2 } from '@fortawesome/pro-solid-svg-icons'

export default {
  directives: {
    clickOutside: vClickOutside
  },
  components:{
    EditorContent,
  },
  props:{
    modelValue:{
      type: String,
      default: null
    },
    label:{
      type: String,
      default: null
    }
  },
  emits:['update:modelValue'],
  data () {
    return {
      editor: null,
      faBold,
      faItalic,
      faH1,
      faH2,
      activeButton: 'bg-gray-300 hover:bg-gray-400',
      disabledButton: 'hover:bg-gray-100',
      showMenu: false
    }
  },
  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.modelValue, false)
    },
  },
  mounted(){
    this.editor = new Editor({
      editorProps: {
        attributes: {
          class: 'p-2 overflow-y-scroll h-40 focus:outline-none'
        }
      },
      content: this.modelValue,
      extensions: [...defaultExtensions(), Heading.configure({levels: [5, 6],})],
      onUpdate: () => {
        this.$emit('update:modelValue', this.editor.getHTML())
      },
    })
  },
  beforeUnmount(){
    this.editor.destroy()
  },
  methods:{
    onClickOutside(){
      this.showMenu = false
    }
  }
}
</script>
