import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { FeathersVuex } from './feathers-client'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


import './assets/scss/main.scss'

const app = createApp(App)
  .use(router)
  .use(FeathersVuex)
  .use(store)

app.component('FontAwesomeIcon', FontAwesomeIcon)
import './plugins/vee-validate/vee-validate.js'

app
  .mount('#app')
