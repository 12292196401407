<template>
  <div v-if="pages.length > 1">
    <div class="flex justify-center">
      <div class="border rounded ">
        <button
          class="border-l p-2"
          :disabled="modelValue <= 1"
          @click="$emit('update:modelValue', modelValue-1); "
        >
          Prev
        </button>
        <button
          class="border-l p-1 w-10"
          :class="[modelValue === 1 ? 'font-bold' : 'font-normal']"
          @click="$emit('update:modelValue', 1)"
        >
          1
        </button>
        <span
          v-if="modelValue-show > 2"
          class="border-l p-2 w-10"
        >
          ...
        </span>
        <button
          v-for="(page, index) in pagesBetween"
          :key="index"
          class="border-l p-1 w-10"
          :class="[modelValue === page ? 'font-bold' : 'font-normal', page < modelValue-show || page > modelValue+show ? 'hidden' : 'inline']"
          @click="$emit('update:modelValue', page)"
        >
          {{ page }}
        </button>
        <span
          v-if="modelValue < pages.length-show"
          class="border-l p-2 w-10"
        >
          ...
        </span>
        <button
          class="border-l p-1 w-10"
          :class="[modelValue === pages.length ? 'font-bold' : 'font-normal']"
          @click="$emit('update:modelValue', pages.length)"
        >
          {{ pages.length }}
        </button>
        <button
          class="border-l p-2"
          :disabled="modelValue >= pages.length"
          @click="$emit('update:modelValue', modelValue+1);"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    totalNumber: {
      type: Number,
      required: true
    },
    numberOfItems: {
      type: Number,
      required: true
    },
    showPages:{
      type: Number,
      default: 1
    },
    modelValue:{
      type: Number,
      required: true
    }
  },
  emits:['update:modelValue'],
  data(){
    return{
    }
  },
  computed:{
    pages(){
      let arr =  Array(Math.ceil(this.totalNumber / this.numberOfItems)) //Brechnet Anzahl der benötigten Seiten
      for (let index = 0; index < arr.length; index++) {
        arr[index] = index+1 //Seitenzahl als Value

      }
      return arr
    },
    pagesBetween(){ // erste und letzte Seite sind hardcoded und werden immer angezeigt
      let arr = [...this.pages]
      arr.shift() // erste Seite entfernen
      arr.pop() // letzte Seite entfernen
      return arr // arr = [2, letzteSeite[
    },
    show(){
      if (this.showPages === 0) { // bei 0 ist die Beschränkung aufgehoben, es können alle Seiten ausgewählt werden.
        return this.pages.length
      }
      return this.showPages
    }
  }
}
</script>
