import { createWebHistory, createRouter } from 'vue-router'
import authenticationGuard from './authenticationGuard.js'
import tenantGuard from './tenantGuard.js'

import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import RegisterSuccess from '../views/RegisterSuccess.vue'
import CustomerList from '../views/Customer/CustomerList.vue'
import CustomerDetail from '../views/Customer/CustomerDetail.vue'
import TenantList from '../views/Tenant/TenantList.vue'
import TenantEdit from '../views/Tenant/TenantEdit.vue'
import TenantCreate from '../views/Tenant/TenantCreate.vue'
import AppAppearance from '../views/AppAppearance.vue'
import AppTexts from '../views/AppTexts.vue'
import IndividualLinks from '../views/IndividualLinks.vue'
import Account from '../views/Account.vue'
import FaqList from '../views/FaqList.vue'
import ContractCompaniesUserList from '../views/ContractCompanies/ContractCompaniesUserList.vue'
import ContractCompaniesUserDetail from '../views/ContractCompanies/ContractCompaniesUserDetail.vue'
import ContractCompaniesList from '../views/ContractCompanies/ContractCompaniesList.vue'
import ContractCompaniesDetail from '../views/ContractCompanies/ContractCompaniesDetail.vue'
import ContractCompaniesCreate from '../views/ContractCompanies/ContractCompaniesCreate.vue'
import Privacy from '../views/Privacy.vue'
import Impressum from '../views/Impressum.vue'
import InvitationList from '../views/InvitationList.vue'
import LayoutApp from '../components/LayoutApp.vue'
import ContractKindsList from '../views/ContractKinds/ContractKindsList.vue'
import ContractKindsDetail from '../views/ContractKinds/ContractKindsDetail.vue'
import ContractKindsCreate from '../views/ContractKinds/ContractKindsCreate.vue'
import EmployeesList from '../views/Employees/EmployeesList.vue'
import EmployeesCreate from '../views/Employees/EmployeesCreate.vue'
import EmployeesDetail from '../views/Employees/EmployeesDetail.vue'
import EmployeesEdit  from '../views/Employees/EmployeesEdit'

import { publicPath } from '../../vue.config'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register/:invitationToken',
    name: 'Register',
    component: Register
  },
  {
    path: '/register/success/:tenantId',
    name: 'RegisterSuccess',
    component: RegisterSuccess
  },
  {
    path: '/app',
    component: LayoutApp,
    children: [
      // tenant routen:
      {
        path: ':tenantId/customer',
        name: 'CustomerList',
        component: CustomerList
      },
      {
        path: ':tenantId/invitations',
        name: 'InvitationList',
        component: InvitationList
      },
      {
        path: ':tenantId/customer/:userId',
        name: 'CustomerDetail',
        component: CustomerDetail
      },
      {
        path: ':tenantId/employees',
        name: 'EmployeesList',
        component: EmployeesList
      },
      {
        path: ':tenantId/employees/create',
        name: 'EmployeesCreate',
        component: EmployeesCreate
      },
      {
        path: ':tenantId/employees/:employeeId/detail',
        name: 'EmployeesDetail',
        component: EmployeesDetail
      },
      {
        path: ':tenantId/employees/:employeeId/edit',
        name: 'EmployeesEdit',
        component: EmployeesEdit
      },
      {
        path: ':tenantId/faq',
        name: 'FaqList',
        component: FaqList
      },
      {
        name: 'AppAppearance',
        path: ':tenantId/appearance',
        component: AppAppearance
      },
      {
        name: 'AppTexts',
        path: ':tenantId/apptexts',
        component: AppTexts
      },
      {
        name: 'IndividualLinks',
        path: ':tenantId/individualLinks',
        component: IndividualLinks
      },
      {
        name: 'Account',
        path: ':tenantId/account',
        component: Account // TODO
      },
      // admin
      {
        path: 'tenant',
        name: 'TenantList',
        component: TenantList
      },
      {
        path: 'tenant/create',
        name: 'TenantCreate',
        component: TenantCreate
      },
      {
        path: 'tenant/:tenantId/edit',
        name: 'TenantEdit',
        component: TenantEdit
      },
      {
        path: 'tenant/:tenantId/contract-companies',
        name: 'ContractCompaniesUserList',
        component: ContractCompaniesUserList
      },
      {
        path: 'tenant/:tenantId/contract-companies/:contractCompanyId',
        name: 'ContractCompaniesUserDetail',
        component: ContractCompaniesUserDetail
      },
      {
        path: 'contract-companies',
        name: 'ContractCompaniesList',
        component: ContractCompaniesList
      },
      {
        path: 'contract-companies/create',
        name: 'ContractCompaniesCreate',
        component: ContractCompaniesCreate
      },
      {
        path: 'contract-companies/:contractCompanyId',
        name: 'ContractCompaniesDetail',
        component: ContractCompaniesDetail
      },
      {
        path: 'contract-kinds',
        name: 'ContractKindsList',
        component: ContractKindsList
      },
      {
        path: 'contract-kinds/:contractKindId',
        name: 'ContractKindsDetail',
        component: ContractKindsDetail
      },
      {
        path: 'contract-kinds/create',
        name: 'ContractKindsCreate',
        component: ContractKindsCreate
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: Privacy
      },
      {
        path: 'impressum',
        name: 'Impressum',
        component: Impressum
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  base: publicPath
})

router.beforeEach(authenticationGuard)
router.beforeEach(tenantGuard)

export default router
