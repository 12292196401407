<template>
  <div>
    <LayoutHeader>
      <template #default>
        Einladungen
      </template>
      <template #actions>
        <button
          type="button"
          class="ml-3 inline-flex items-center px-4 py-2 border border-green-600 rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          @click="$refs.modal.showModal()"
        >
          Einladen
        </button>
      </template>
    </LayoutHeader>
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="firstname"
              @update:modelValue="fetchData"
            >
              Vorame
            </SortButton>
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="lastname"
              @update:modelValue="fetchData"
            >
              Nachname
            </SortButton>
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            E-Mail
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="createdAt"
              @update:modelValue="fetchData"
            >
              Erstellt Am
            </SortButton>
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Status
          </td>
          <td />
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr
          v-for="invitation of invitations"
          :key="invitation._id"
        >
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ invitation.firstname }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ invitation.lastname }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ invitation.email }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ dayjs(invitation.createdAt).format('DD.MM.YYYY HH:mm') }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <span v-if="invitation.active">
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
              >
                Gesendet <b v-if="successSendingInvitation.includes(invitation._id)">&nbsp;(Erneut)</b>
              </span>

              <button
                v-if="!pendingSendingInvitation.includes(invitation._id) && !successSendingInvitation.includes(invitation._id)"
                type="button"
                class="ml-3 inline-flex items-center px-2 py-1 border border-green-600 rounded-md shadow-sm text-sm font-small text-green-600 bg-white hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                @click="resendCustomerInvitation(invitation._id)"
              >
                Nochmal senden
              </button>
            </span>
            <span
              v-else
              class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
            >
              Inaktiv
            </span>
          </td>
          <td>
            <button
              class="btn btn-sm btn-success text-red-600 text-sm hover:text-red-700 btn-table"
              @click="softDelete(invitation)"
            >
              löschen
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      v-if="totalNumber"
      v-model="page"
      :number-of-items="perPage"
      :total-number="totalNumber"
      @update:modelValue="fetchData"
    />
    <div class="p-4">
      <InviteCustomerModal
        ref="modal"
        @fetchData="fetchData()"
      />
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import LayoutHeader from '../components/LayoutHeader.vue'
import InviteCustomerModal from '@/components/modals/InviteCustomerModal'
import Pagination from '../components/Pagination.vue'
import SortButton from '../components/SortButton.vue'

export default {
  components: {
    LayoutHeader,
    InviteCustomerModal,
    Pagination,
    SortButton
  },
  data(){
    return {
      pendingSendingInvitation: [],
      successSendingInvitation: [],
      dayjs,
      inviteCustomer: false,
      invitations: null,
      //Pagination
      totalNumber: null,
      perPage: 50, // Eintraege pro Seite
      page: 1, // Aktuelle Seite
      //SortButton
      sortObj: {
        sortBy: 'name', //propertyNameInMongo
        sortDirection: 1 // -1 = inverse
      }
    }
  },
  computed: {
  },
  watch: {
    $route: {
      handler: 'fetchData',
      immediate: true
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData(){
      const invitationsReq = await this.$store.dispatch('invitations/find', {
        query: {
          tenantId: this.$route.params.tenantId,
          // active: true,
          $limit: this.perPage, $skip: (this.page -1)* this.perPage,
          $sort:{[this.sortObj.sortBy]: this.sortObj.sortDirection}}})

      this.invitations = invitationsReq.data
      this.totalNumber = invitationsReq.total
    },
    async softDelete(invitation){
      invitation.active = false
      await invitation.save()
      this.fetchData()
    },
    async resendCustomerInvitation(invitationId) {
      try {
        this.pendingSendingInvitation.push(invitationId)
        this.$store.dispatch('auth-management/create', {
          action: 'sendCustomerInvitation',
          invitationId
        })
        this.successSendingInvitation.push(invitationId)
      } finally {
        const index = this.pendingSendingInvitation.indexOf(invitationId)
        if (index > -1) {
          this.pendingSendingInvitation.splice(index, 1)
        }
      }
    }
  }

}
</script>
