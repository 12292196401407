<template>
  <div>
    <LayoutHeader>
      <template #default>
        Vertragsarten
      </template>
      <template #actions>
        <button
          type="button"
          class="ml-3 inline-flex items-center px-4 py-2 border border-green-600 rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          @click="$router.push({name: 'ContractKindsCreate'})"
        >
          Hinzufügen
        </button>
      </template>
    </LayoutHeader>
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="name"
              @update:modelValue="fetchData"
            >
              Name
            </SortButton>
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="product"
              @update:modelValue="fetchData"
            >
              Produkt
            </SortButton>
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="branch"
              @update:modelValue="fetchData"
            >
              Sparte
            </SortButton>
          </td>
          <td />
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr
          v-for="contractKind of contractKindsList"
          :key="contractKind._id"
        >
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ contractKind.name }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ contractKind.product }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ contractKind.branch }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-red-500">
            <router-link
              class="btn btn-sm btn-success text-indigo-600 text-sm hover:text-indigo-700 btn-table"
              :to="{ name: 'ContractKindsDetail', params: { contractKindId: contractKind._id }}"
            >
              Details
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      v-if="totalNumber"
      v-model="page"
      :number-of-items="perPage"
      :total-number="totalNumber"
      @update:modelValue="fetchData"
    />
  </div>
</template>
<script>
import LayoutHeader from '../../components/LayoutHeader.vue'
import Pagination from '../../components/Pagination.vue'
import SortButton from '../../components/SortButton.vue'


export default {
  components: { LayoutHeader, Pagination, SortButton },
  data(){
    return{
      createContractKind: false,
      contractKindsList: null,
      //Pagination
      totalNumber: null,
      perPage: 50, // Eintraege pro Seite
      page: 1, // Aktuelle Seite
      //SortButton
      sortObj: {
        sortBy: 'name', //propertyNameInMongo
        sortDirection: 1 // -1 = inverse
      }
    }
  },
  computed: {
  },
  watch: {
    $route: {
      handler: 'fetchData',
      immediate: true
    }
  },
  methods: {
    async fetchData () {
      let findRequest = await this.$store.dispatch('contract-kinds/find', {
        query: {
          $limit: this.perPage, $skip: (this.page -1)* this.perPage,
          $sort:{[this.sortObj.sortBy]: this.sortObj.sortDirection}}})
      this.contractKindsList = findRequest.data
      this.totalNumber = findRequest.total
    },
  }

}
</script>
