<template>
  <transition name="modalfade">
    <div
      v-if="visible"
      class="backdrop fixed inset-0 flex items-center justify-center z-modal-backdrop overflow-hidden"
      @mousedown.self="hide()"
    >
      <div class="container width">
        <div
          class="modal z-modal bg-white rounded-md p-6"
          @keyup.esc="hide()"
        >
          <slot
            :hide="hide"
            :context="context"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data: () => ({
    visible: false,
    context: undefined
  }),
  watch: {
    visible () {
      document.documentElement.style.overflow = this.visible ? 'hidden' : 'auto'
    }
  },
  methods: {
    show (context = {}) {
      window.addEventListener('keydown', this.handleKeypress)
      this.visible = true
      this.context = context
    },
    hide () {
      this.visible = false
      this.context = undefined
      window.removeEventListener('keydown', this.handleKeypress)
    },
    handleKeypress (e) {
      if (e.key === 'Escape') {
        this.hide()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.backdrop{
  background-color: rgba($color: #000000, $alpha: .4);
}
.width {
  width: 100%;
}
@media (min-width: 576px) {
  .width {
    width: 30rem;
  }
}
@media (min-width: 768px) {
  .width {
    width: 40rem;
  }
}

.modalfade-enter-active, .modalfade-leave-active {
  transition: opacity 300ms;
}
.modalfade-enter-to, .modalfade-leave /* .modalfade-leave-active below version 2.1.8 */ {
  opacity: 1;
}
.modalfade-enter, .modalfade-leave-to /* .modalfade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.modalfade-enter-active .modal, .modalfade-leave-active .modal {
  transition: transform 300ms;
}
.modalfade-enter-to .modal, .modalfade-leave .modal /* .modalfade-leave-active below version 2.1.8 */ {
  transform: scale(1);
}
.modalfade-enter .modal, .modalfade-leave-to .modal /* .modalfade-leave-active below version 2.1.8 */ {
  transform: scale(1.1);
}
</style>
