<template>
  <div>
    <LayoutHeader>
      <template #default>
        Vertragsart Hinzufügen
      </template>
    </LayoutHeader>
    <Form @submit="save">
      <div class="bg-white py-6 px-4 sm:p-6">
        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <BasicInput
              id="name"
              v-model="contractKind.name"
              type="text"
              name="name"
              input-class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              label="Name"
              label-class="block text-sm font-medium text-gray-700"
              rules="required"
            />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <BasicInput
              id="product"
              v-model="contractKind.product"
              type="text"
              name="product"
              input-class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              label="Produkt"
              label-class="block text-sm font-medium text-gray-700"
              rules="required"
            />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <BasicInput
              id="branch"
              v-model="contractKind.branch"
              type="text"
              name="branch"
              input-class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              label="Sparte"
              label-class="block text-sm font-medium text-gray-700"
              rules="required"
            />
          </div>
          <div class="col-span-full">
            <div class="pt-5">
              <div class="flex justify-end">
                <button
                  type="button"
                  class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="$router.go(-1)"
                >
                  Zurück
                </button>
                <input
                  type="submit"
                  value="Speichern"
                  class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>
<script>
import LayoutHeader from '../../components/LayoutHeader.vue'
import { models } from '@/feathers-client'
import BasicInput from '../../components/BasicInput.vue'
import {Form} from 'vee-validate'
export default {
  components: { LayoutHeader, BasicInput, Form },
  data () {
    return {
      contractKind: new models.api.ContractKinds({
        name: '',
        product: '',
        branch: ''
      }),
      message: '',
      messageColor: 'red',
      messageVisible: false
    }
  },
  created() {
    this.fetchData()
  },
  methods:{
    async fetchData() {
      
    },
    async save(){
      try {
        await this.contractKind.save()
        this.$router.push({name: 'ContractKindsList'})
      } catch (error) {
        console.error(error)
      }      
    },
  }
}
</script>