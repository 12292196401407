<template>
  <div>
    <LayoutHeader>
      <template #default>
        Mitarbeiter bearbeiten
      </template>
      <template #actions>
        <button
          type="button"
          class="ml-3 inline-flex items-center px-4 py-2 border border-red-600 rounded-md shadow-sm text-sm font-medium text-red-600 bg-white hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          @click="deleteEmployee()"
        >
          Löschen
        </button>
      </template>
    </LayoutHeader>
    <div
      v-if="employee"
      class="p-4"
    >
      <EmployeesForm :employee-prop="employee" />
    </div>
  </div>
</template>
<script>
import EmployeesForm from './components/EmployeesForm.vue'
import LayoutHeader from '../../components/LayoutHeader.vue'
export default {
  components: { LayoutHeader, EmployeesForm },
  data(){
    return{
      employee: null,
      password: null
    }
  },
  created(){
    this.fetchData()
  },
  methods: {
    async fetchData(){
      this.employee = await this.$store.dispatch('users/get', this.$route.params.employeeId)
      if (this.employee.address == null) {
        this.employee.address = {
          street: '',
          streetNumber: '',
          zip: '',
          city:''
        }
      }
    },
    async deleteEmployee() {
      try {
        await this.$store.dispatch('users/remove',[this.employee._id])
        this.$router.push({name: 'EmployeesList'})
      } catch (error) {
        console.error(error)
      }
    }
  }
    
}
</script>