<template>
  <div>
    <label
      v-if="type !== 'checkbox'"
      :for="id"
      :class="labelClass"
    >{{ label }}
      <small>
        {{ small }}
      </small>
    </label>
    <div
      class="flex rounded"
      :class="{'shadow': shadow}"
    >
      <Field
        v-if="setType"
        :id="id"
        v-slot="args"
        :class="[inputClass, {'rounded-r-none':showButton}]"
        :model-value="modelValue"
        :type="localType"
        :as="as"
        :name="id"
        :placeholder="placeholder"
        :rules="rules"
        :disabled="disabled"
        @input="updateModel"
      >
        <slot v-bind="args" />
      </Field>
      <Field
        v-else
        :id="id"
        v-slot="args"
        :model-value="modelValue"
        :as="as"
        :name="id"
        :class="inputClass"
        :rules="rules"
        @input="(event) => $emit('update:modelValue', event.target.value)"
      >
        <slot v-bind="args" />
      </Field>
      <button
        v-if="showButton"
        type="button"
        class="btn rounded-l-none bg-white border border-l-0"
        :class="buttonStyle"
        @click="toggleType"
      >
        <FontAwesomeIcon
          v-if="localType==='password'"
          :icon="faEye"
        />
        <FontAwesomeIcon
          v-else
          :icon="faEyeSlash"
        />
      </button>
      <label
        v-if="type === 'checkbox'"
        :for="id"
      >{{ label }}
      </label>
    </div>
    <ErrorMessage
      v-slot="{ message }"
      :name="id"
    >
      <!-- <p
        v-if="Object.keys(errors).length"
        id="email-error"
        class="mt-2 text-sm text-red-600"
      >
        {{ message }}
      </p> -->
      <p class="mt-2 text-sm text-red-600">
        {{ message }}
      </p>
    </ErrorMessage>
  </div>
</template>
<script>
import { Field, ErrorMessage  } from 'vee-validate'
import { faEye, faEyeSlash } from '@fortawesome/pro-duotone-svg-icons'


export default {
  components: {
    Field,
    ErrorMessage
  },
  props: {
    id: {
      required: true,
      type: String
    },
    inputClass: {
      required: false,
      type: String,
      default: null
    },
    label: {
      required: true,
      type: String
    },
    labelClass:{
      required: false,
      type: String,
      default: null
    },
    small: {
      required: false,
      type: String,
      default: null
    },
    placeholder: {
      required: false,
      type: String,
      default: null
    },
    rules: {
      required: false,
      type: [Object,String, Function],
      default: () => { return {} }
    },
    type: {
      required: false,
      type: String,
      default: 'text'
    },
    as: {
      required: false,
      type: String,
      default: 'input'
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    modelValue: {
      required: false,
      type: [String, Number, Boolean],
      default: ''
    },
    showButton:{
      type: Boolean,
      default: false
    },
    buttonStyle:{
      type: String,
      default: 'border-gray-500'
    },
    shadow:{
      type: Boolean,
      default: false
    }
  },
  emits: {
    'update:modelValue': {
      type: String
    }
  },
  data(){
    return{
      localType: this.type,
      faEye,
      faEyeSlash
    }
  },
  computed: {
    setType() {
      return this.as !== 'select' && this.as !== 'textarea'
    }
  },
  methods: {
    updateModel(event){
      if (this.type === 'checkbox') {
        this.$emit('update:modelValue', event.target.checked)
      }
      else{
        this.$emit('update:modelValue', event.target.value)
      }
    },
    isRequired(value) {
      if (value && value.trim()) {
        return true
      }

      return 'This is required'
    },
    toggleType(){
      if (this.localType === 'password'){
        this.localType = 'text'
      }else{
        this.localType = 'password'
      }
    }
  },
}
</script>
