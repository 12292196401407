<template>
  <div>
    <LayoutHeader>
      Mitarbeiter hinzufügen
    </LayoutHeader>
    <div
      class="p-4"
    >
      <EmployeesForm require-password />
    </div>
  </div>
</template>
<script>
import LayoutHeader from '../../components/LayoutHeader.vue'
import { models } from '@/feathers-client'
import EmployeesForm from './components/EmployeesForm.vue'
export default {
  components: { LayoutHeader, EmployeesForm },
  data(){
    return{
      employee: new models.api.User({
        firstname: '',
        lastname:'',
        role: '',
        email: '',
        address: {
          street: '',
          streetNumber:'',
          zip: '',
          city: '',
        }
      }),
      password: null,
    }
  },
  methods: {
    async save (){
      this.employee.tenantId = this.$route.params.tenantId
      this.employee.password = this.password
      try {
        await this.employee.save()
        this.$router.push({name: 'EmployeesList'})
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
