<template>
  <div>
    <layout-header>
      Gestaltung
    </layout-header>
    <div
      v-if="tenant != null && !loading"
      class="p-4"
    >
      <Form>
        <div class="grid grid-cols-1 lg:grid-cols-2">
          <div class="col-span-1 col-start-1 mt-4">
            <h4 class="mt-2">
              Farbe
            </h4>

            <label
              class="text-lg font-medium text-gray-700 flex"
              for="color"
            >
              <span class="border rounded p-2 flex flex-initial">
                <div
                  :style="{backgroundColor: tenant.color}"
                  class="border rounded-full h-5 w-5 mt-1 mr-1"
                />
                Farbe wählen
              </span>
              <input
                id="color"
                v-model="tenant.color"
                type="color"
                name="color"
                class="invisible"
              >
            </label>
          </div>
          <div class="mt-4 col-start-1">
            <h4 class="mt-2">
              Call to Action Buttons
            </h4>
            <h6>App <small>(runder Button)</small></h6>
            <div class="grid gap-2 mb-4">
              <BasicInput
                id="App-Titel"
                v-model="tenant.callToAction.app.title"
                label="Titel"
                name="ctaButtonText"
                input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                label-class="block text-sm font-medium text-gray-700"
                rules="max:20"
                class="w-1/2"
              />
              <BasicInput
                id="App-Link"
                v-model="tenant.callToAction.app.link"
                label="Link"
                name="ctaButtonUrl"
                input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                label-class="block text-sm font-medium text-gray-700"
                rules="url"
                class="w-1/2"
              />
            </div>
            <h6>Vertragsliste</h6>
            <div class="grid gap-2">
              <BasicInput
                id="Vertragsliste-Titel"
                v-model="tenant.callToAction.contractList.title"
                label="Titel"
                name="ctaButtonText"
                input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                label-class="block text-sm font-medium text-gray-700"
                class="w-1/2"
              />
              <BasicInput
                id="Vertragsliste-Link"
                v-model="tenant.callToAction.contractList.link"
                label="Link"
                name="ctaButtonUrl"
                input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                label-class="block text-sm font-medium text-gray-700"
                rules="url"
                class="w-1/2"
              />
              <BasicInput
                id="Vertragsliste-Beschreibung"
                v-model="tenant.callToAction.contractList.description"
                as="textarea"
                label="Beschreibung"
                input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                label-class="block text-sm font-medium text-gray-700"
                class="w-1/2"
              />
            </div>
          </div>
          <div class="col-span-1 col-start-1 mt-4">
            <h4>Logo</h4>
            <div class="flex gap-4">
              <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div class="space-y-1 text-center">
                  <svg
                    class="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="flex text-sm text-gray-600">
                    <label
                      for="file-upload"
                      class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span>Logo hochladen</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        class="hidden"
                        @change="upload"
                      >
                    </label>
                    <p class="pl-1">
                      oder hineinziehen
                    </p>
                  </div>
                  <p class="text-xs text-gray-500">
                    PNG, JPG
                  </p>
                </div>
              </div>
              <div>
                <img
                  v-if="tenant.logoId"
                  :src="tenant.logo.downloadUrl"
                  alt=""
                  class="h-24"
                >
                <div class="mt-2">
                  <div class="col-span-2">
                    <button
                      v-if="tenant.logoId"
                      type="submit"
                      class="text-red-500 text-sm font-medium"
                      @click="removeLogo"
                    >
                      Logo entfernen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-start-1 mt-4">
            <h4>Eigenverträge vom Kunden:</h4>
            <BasicInput
              id="sendToAgent"
              v-model="tenant.customerContractsOptions.alwaysSendToAgent"
              type="checkbox"
              label="Immer an Makler senden?"
              input-class="rounded mt-1 mr-2"
            />
            <BasicInput
              id="sendToAgentText"
              v-model="tenant.customerContractsOptions.alwaysSendToAgentText"
              class="mt-2 ml-4 w-1/2"
              type="text"
              label="Hinweistext"
              input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              label-class="block text-sm font-medium text-gray-700"
            />
          </div>
          <div class="col-start-1 mt-4">
            <h4>
              Fremdverträge:
            </h4>
            <div class="flex">
              <div class="flex bg-gray-200 rounded-md">
                <button
                  id="thirdPartyContractsDisabled"
                  type="button"
                  class="px-2 py-1 rounded-md text-black m-1"
                  :class="{
                    'bg-red-500 hover:bg-red-600 text-white': tenant.disableThirdPartyContracts,
                  }"
                  @click="tenant.disableThirdPartyContracts = true"
                >
                  Deaktiviert
                </button>
                <button
                  id="thirdPartyContractsEnabled"
                  type="button"
                  class="px-2 py-1 rounded-md text-black m-1"
                  :class="{
                    'bg-green-500 hover:bg-green-600 text-white': !tenant.disableThirdPartyContracts,
                  }"
                  @click="tenant.disableThirdPartyContracts = false"
                >
                  Aktiviert
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          type="button"
          @click="save"
        >
          Speichern
        </button>
      </Form>
    </div>
    <div v-else>
      Loading ...
    </div>
  </div>
</template>
<script>
import LayoutHeader from '../components/LayoutHeader.vue'
import store from '@/store'
import feathers from '@/feathers-client'
import axios from 'axios'
import BasicInput from '../components/BasicInput.vue'
import { Form } from 'vee-validate'

export default {
  components: {
    LayoutHeader,
    BasicInput,
    Form
  },
  data () {
    return {
      tenant: null,
      loading: true,
    }
  },
  watch: {
    '$route': {
      handler: 'fetchData',
      immediate: true
    }
  },
  created(){
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loading = true
      try {
        this.tenant = await store.dispatch('tenants/get', this.$route.params.tenantId)
        if(!this.tenant.callToAction){
          this.tenant.callToAction = {app: {}, contractList:{}}
        }
        if (!this.tenant.customerContractsOptions) {
          this.tenant.customerContractsOptions = {alwaysSendToAgent: false, alwaysSendToAgentText: 'Ihrem Makler zeigen?'}
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }

    },
    async save (){
      if (!this.tenant.invitationText) this.tenant.invitationText = null
      await this.tenant.save()
      alert('Gespeichert')
    },
    async upload(event){
      try {
        const toUploadFile = event.target.files[0]

        const file = await feathers.service('file').create({
          name: toUploadFile.name,
          mimeType: toUploadFile.type
        })
        await axios.put(file.uploadUrl, toUploadFile)

        this.tenant.logo = file
        this.tenant.logoId = file._id
      } catch (error) {
        console.error(error)
      }
    },
    removeLogo(){
      this.tenant.logo = null
      this.tenant.logoId = null
    }
  }
}
</script>
