<template>
  <Form @submit="save">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-24">
      <div class="grid-cols-1">
        <div class="grid grid-cols-4 gap-2">
          <h4>Kontaktinformationen</h4>
          <div
            class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-2 col-span-full"
            role="alert"
          >
            <p>Kontaktinformationen können vom Makler bearbeitet werden.</p>
          </div>
          <div class="col-span-4">
            <BasicInput
              id="name"
              v-model="tenant.name"
              type="text"
              name="name"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Name"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              rules="required"
            />
          </div>
          <div class="col-span-3">
            <BasicInput
              id="street_address"
              v-model="tenant.address.street"
              type="text"
              name="street_address"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Straße"
              rules="required"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
          <div class="col-span-1">
            <BasicInput
              id="street_number_address"
              v-model="tenant.address.streetNumber"
              type="text"
              name="street_number_address"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Nr."
              rules="required"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
          <div class="col-span-2">
            <BasicInput
              id="zip"
              v-model="tenant.address.zip"
              type="text"
              name="zip"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Postleitzahl"
              rules="required"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
          <div class="col-span-2">
            <BasicInput
              id="city"
              v-model="tenant.address.city"
              type="text"
              name="city"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Stadt/Ort"
              rules="required"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
          <!-- TODO Email Und Telefon gesondert (Kontaktinformationen fpür Kunden gleich?) -->
          <div class="col-span-4">
            <BasicInput
              id="email_for_customer"
              v-model="tenant.email"
              type="email"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Email für Kunden"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              rules="email-pattern-optional"
            />
          </div>
          <div class="col-span-4">
            <BasicInput
              id="phone_for_customer"
              v-model="tenant.phone"
              type="text"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Telefon für Kunden"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              rules="phone-pattern-optional"
            />
          </div>
          <div class="col-span-full">
            <h4 class="mb-0">
              Direkter Kontakt
            </h4>
          </div>
          <div class="col-span-4">
            <BasicInput
              id="email"
              v-model="tenant.directEmail"
              type="email"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Email"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              rules="email-pattern-optional"
            />
          </div>
          <div class="col-span-4">
            <BasicInput
              id="phone"
              v-model="tenant.directPhone"
              type="text"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Telefon"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              rules="phone-pattern-optional"
            />
          </div>
        </div>
      </div>
      <div class="grid-cols-1">
        <h4>Vertragsinformationen</h4>
        <div class="grid grid-cols-4 gap-2">
          <div class="col-span-full">
            <BasicInput
              id="customer_number"
              v-model="tenant.contract.customerNumber"
              type="text"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Kundennummer"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
          <div class="col-span-full">
            <BasicInput
              id="status"
              v-model="tenant.status"
              as="select"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Status"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              <option value="ANBAHNUNG">
                Anbahnung
              </option>
              <option value="AKTIV">
                Aktiv
              </option>
              <option value="STORNO">
                Storno
              </option>
            </BasicInput>
          </div>
          <div class="col-span-2">
            <BasicInput
              id="start_date"
              v-model="startDate"
              type="date"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Vertragsbeginn"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
          <div class="col-span-2">
            <BasicInput
              id="end_date"
              v-model="endDate"
              type="date"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Vertragsende"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
          <div class="col-span-full">
            <BasicInput
              id="rate"
              v-model="tenant.contract.rate"
              type="text"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Tarif"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
          <div class="col-span-full">
            <BasicInput
              id="fee_monthly"
              v-model="tenant.contract.feeMonthly"
              type="number"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Monatsgebühr"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
          <div class="col-span-full">
            <BasicInput
              id="fee_annually"
              v-model="tenant.contract.feeAnnually"
              type="number"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Jahresgebühr"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
          <div class="col-span-full">
            <BasicInput
              id="total"
              v-model="total"
              type="text"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Jahresgebühr gesamt"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              disabled
            />
          </div>
          <div class="col-span-full">
            <BasicInput
              id="comment"
              v-model="tenant.contract.comment"
              as="textarea"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Bermerkung"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
        </div>
      </div>
      <div class="grid-cols-1">
        <div class="grid grid-cols-4 gap-2">
          <h4 class="col-span-4">Technischer Benutzer</h4>
          <div v-if="adminAssfinetPasswordBad"
            class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-2 col-span-full"
            role="alert"
          >
            <p>Die Assfinet Zugansdaten sind nicht gültig oder haben nicht die nötigen Berechtigung</p>
          </div>
          <div class="col-span-4">
            <BasicInput
              id="adminAssfinetEmail"
              v-model="tenant.adminAssfinetEmail"
              type="text"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Email"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
          <div class="col-span-4">
            <BasicInput
              id="adminAssfinetPassword"
              v-model="tenant.adminAssfinetPassword"
              type="text"
              input-class="w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              label="Passwort"
              label-class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            />
          </div>
        </div>
      </div>
      <div class="pt-5 lg:col-start-2 justify-self-end">
        <div class="">
          <button
            type="button"
            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="back()"
          >
            Zurück
          </button>
          <button
            type="submit"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Speichern
          </button>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import { models } from '@/feathers-client'
import BasicInput from '../../../components/BasicInput.vue'
import {Form} from 'vee-validate'

export default {
  components: {
    BasicInput,
    Form
  },
  props:{
    tenantToEdit:{
      type: Object,
      default: null
    }
  },
  data(){
    return{
      dayjs,
      adminAssfinetPasswordBad: false
    }
  },
  computed:{
    tenant(){
      if (this.tenantToEdit){
        const t = this.tenantToEdit
        if (!t.contract) {
          t.contract = {}
        }
        if (!t.address) {
          t.address = {}
        }
        return t
      }
      return new models.api.Tenants({
        address: {},
        contract: {},
      })
    },
    total(){
      return Number(this.tenant.contract.feeAnnually) + Number(this.tenant.contract.feeMonthly) * 12
    },
    startDate:{
      get(){
        return dayjs(this.tenant.contract.startDate).format('YYYY-MM-DD')
      },
      set(value){
        this.tenant.contract.startDate = value
      }
    },
    endDate:{
      get(){
        return dayjs(this.tenant.contract.endDate).format('YYYY-MM-DD')
      },
      set(value){
        this.tenant.contract.endDate = value
      }
    }
  },
  methods:{
    async save(){
      this.adminAssfinetPasswordBad = false
      try {
        await this.tenant.save()
        alert('Änderungen gespeichert.')
        // this.$router.push({ name: 'TenantList'})()
      } catch (error) {
        if(error.message === 'Wrong adminAssfinetPassword') this.adminAssfinetPasswordBad = true
        console.error(error)
      }
    },
    back () {
      this.$router.push({ name: 'TenantList' })
    }
  }
}
</script>
