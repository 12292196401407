import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class ContractCompaniesTenant extends BaseModel {
  constructor (data, options) {
    super(data, options)
  }

  static instanceDefaults () {
    return {}
  }
}

ContractCompaniesTenant.modelName = 'ContractCompaniesTenant'

const servicePath = 'contract-companies-tenant'
const servicePlugin = makeServicePlugin({
  Model: ContractCompaniesTenant,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({
  before: {
    find: [],
  },
  after: {
    find: [],
  },
  error: {
    find: [],
  }
})

export default servicePlugin
