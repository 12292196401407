import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class ContractCompanies extends BaseModel {
  constructor (data, options) {
    super(data, options)
  }

  static instanceDefaults () {
    return {}
  }
}

ContractCompanies.modelName = 'ContractCompanies'

const servicePath = 'contract-companies'
const servicePlugin = makeServicePlugin({
  Model: ContractCompanies,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
