<template>
  <div>
    <layout-header>
      Texte
    </layout-header>
    <div
      v-if="tenant != null"
      class="p-4"
    >
      <Form>
        <div>
          <TextEditor
            v-model="tenant.welcomeText"
            label="Willkommen-Text"
          />
          <p class="mt-2 text-sm text-gray-500">
            Hier wird der Text eingegeben, der nach dem Login auf der Kontakt Seite angezeigt wird.
          </p>
        </div>
        <div class="mt-4">
          <TextEditor
            v-model="tenant.impressumText"
            label="Impressum"
          />
        </div>
        <div class="mt-4">
          <TextEditor
            v-model="tenant.agbText"
            label="AGB"
          />
        </div>
        <div class="mt-4">
          <label
            for="invitationText"
            class="block text-sm font-medium text-gray-700"
          >
            Einladungstext
          </label>
          <div class="mt-1">
            <textarea
              id="invitationText"
              v-model="tenant.invitationText"
              name="invitationText"
              rows="3"
              class="shadow-sm focus:ring-light-blue-500 focus:border-light-blue-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>



        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          @click="save"
        >
          Speichern
        </button>
      </Form>
    </div>
  </div>
</template>
<script>
import LayoutHeader from '../components/LayoutHeader.vue'
import store from '@/store'
import TextEditor from '../components/TextEditor.vue'
import { Form } from 'vee-validate'

export default {
  components: {
    LayoutHeader,
    TextEditor,
    Form
  },
  data () {
    return {
      tenant: null,
    }
  },
  watch: {
    '$route': {
      handler: 'fetchData',
      immediate: true
    }
  },
  created(){
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.tenant = await store.dispatch('tenants/get', this.$route.params.tenantId)
    },
    async save (){
      if (!this.tenant.invitationText) this.tenant.invitationText = null
      await this.tenant.save()
      alert('Gespeichert')
    },
  }
}
</script>
