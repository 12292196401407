<template>
  <div>
    <layout-header>
      Makler
    </layout-header>
    <div
      v-if="tenant != null && user != null"
      class="p-4"
    >
      <Form @submit="save">
        <div class="md:grid md:grid-cols-2 md:gap-6">
          <div class="col-span-1">
            <h4>Allgemeine Informationen</h4>
            <div class="grid grid-cols-6 gap-2">
              <div class="col-span-4">
                <div class="mt-1">
                  <BasicInput
                    id="name"
                    v-model="tenant.name"
                    type="text"
                    input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    label-class="block text-sm font-medium text-gray-700"
                    label="Name"
                    rules="required"
                  />
                </div>
              </div>
              <div class="col-span-3">
                <BasicInput
                  id="street"
                  v-model="tenant.address.street"
                  type="text"
                  input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  label="Straße"
                  rules="required"
                  label-class="block text-sm font-medium text-gray-700"
                />
              </div>
              <div class="col-span-1">
                <BasicInput
                  id="number"
                  v-model="tenant.address.streetNumber"
                  type="text"
                  input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  label="Nr."
                  rules="required"
                  label-class="block text-sm font-medium text-gray-700"
                />
              </div>
              <div class="col-start-1 col-span-1">
                <BasicInput
                  id="zip"
                  v-model="tenant.address.zip"
                  type="text"
                  input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  label="PLZ"
                  rules="required"
                  label-class="block text-sm font-medium text-gray-700"
                />
              </div>
              <div class="col-span-3">
                <BasicInput
                  id="city"
                  v-model="tenant.address.city"
                  type="text"
                  input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  label="Ort"
                  rules="required"
                  label-class="block text-sm font-medium text-gray-700"
                />
              </div>
              <div class="col-span-4">
                <BasicInput
                  id="phone"
                  v-model="tenant.phone"
                  type="text"
                  input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  label-class="block text-sm font-medium text-gray-700"
                  label="Telefon"
                  rules="phone-pattern-optional"
                />
              </div>
              <div class="col-span-4">
                <BasicInput
                  id="emailTenant"
                  v-model="tenant.email"
                  type="email"
                  input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  label-class="block text-sm font-medium text-gray-700"
                  label="Kontakt Email"
                  rules="email-pattern-optional"
                />
              </div>
            </div>
          </div>
          <div class="col-span-1 col-start-1">
            <h4>
              Logindaten
            </h4>
            <div class="grid grid-cols-6 gap-2">
              <div class="col-span-4">
                <div class="mt-1">
                  <BasicInput
                    id="email"
                    v-model="user.email"
                    input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    type="text"
                    label="Email"
                    rules="required"
                    label-class="block text-sm font-medium text-gray-700"
                  />
                </div>
              </div>
              <div class="col-span-4">
                <div class="mt-1">
                  <BasicInput
                    id="password"
                    v-model="password"
                    type="password"
                    input-class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-r-0"
                    label="Neues Passwort"
                    label-class="block text-sm font-medium text-gray-700"
                    rules="password-strength-optional"
                    button-style="border-gray-300"
                    show-button
                    shadow
                  />
                </div>
              </div>
              <div class="col-span-4">
                <div class="mt-1">
                  <BasicInput
                    id="confirmpassword"
                    v-model="confirmpassword"
                    type="password"
                    input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-r-0"
                    label="Neues Passwort bestätigen"
                    label-class="block text-sm font-medium text-gray-700"
                    button-style="border-gray-300"
                    show-button
                    shadow
                  />
                </div>
              </div>
              <div class="col-start-1 col-span-4 mt-2">
                <div class="flex justify-end">
                  <input
                    type="submit"
                    value="Speichern"
                    class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import roles from '@/resources/enums/roles.js'
import store from '@/store'
import LayoutHeader from '../components/LayoutHeader.vue'
import BasicInput from '../components/BasicInput.vue'
import {Form} from 'vee-validate'

export default {
  components: { LayoutHeader, BasicInput, Form },
  data () {
    return {
      roles,
      tenant: null,
      user: null,
      password: null,
      confirmpassword: null
    }
  },
  watch: {
    $route : {
      handler: 'fetchData',
      immediate: true
    }
  },
  created(){
    this.fetchData()
  },
  methods: {
    async fetchData(){
      this.tenant = null
      this.tenant = await store.dispatch('tenants/get', this.$route.params.tenantId)
      this.user = await store.getters['auth/user']
    },
    async save () {
      if (this.password !== '' && this.password !== null) {
        if (this.password == this.confirmpassword) {
          this.user.password = this.password
        }
      }else{
        delete this.user.password
      }
      try {
        await this.tenant.save()
        await this.user.save()
        alert('Gespeichert')
      } catch (error) {
        console.error(error)
      }
    },
  }
}
</script>
