<template>
  <div>
    <LayoutHeader>
      <template #default>
        Mitarbeiter
      </template>
      <template #actions>
        <button
          v-if="user.role === 'TENANT' || user.role === 'DEVELOPER'"
          type="button"
          class="ml-3 inline-flex items-center px-4 py-2 border border-green-600 rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          @click="$router.push({name: 'EmployeesCreate', params: {tenantId}})"
        >
          Hinzufügen
        </button>
      </template>
    </LayoutHeader>
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Vorname
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Nachname
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            E-Mail
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Rolle
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Erstellt Am
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="employee in employees"
          :key="employee._id"
        >
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ employee.firstname }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ employee.lastname }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ employee.email }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ roles[employee.role].human }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ dayjs(employee.createdAt).format('DD.MM.YYYY HH:mm') }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <router-link
              class="btn btn-sm btn-success text-indigo-600 text-sm hover:text-indigo-700 btn-table"
              :to="{ name: 'EmployeesDetail', params: { employeeId: employee._id }}"
            >
              Details
            </router-link>
            <router-link
              v-if="user.role === 'TENANT' || user.role === 'DEVELOPER' || user._id === employee._id"
              class="btn btn-sm btn-success text-indigo-600 text-sm hover:text-indigo-700 btn-table"
              :to="{ name: 'EmployeesEdit', params: { employeeId: employee._id }}"
            >
              Edit
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

import roles from '@/resources/enums/roles.js'
import LayoutHeader from '../../components/LayoutHeader'
import dayjs from 'dayjs'

export default {
  components: {LayoutHeader},
  data () {
    return {
      roles,
      dayjs,
      employees: null
    }
  },
  computed:{
    tenantId(){
      return this.$route.params.tenantId
    },
    user(){
      return this.$store.getters['auth/user']
    }
  },
  watch: {
    '$route': {
      handler: 'fetchData',
      immediate: true
    }
  },
  created(){
    this.fetchData
  },
  methods: {
    async fetchData(){
      if(this.$route.params.tenantId && this.$route.params.tenantId !== 'null') {
        this.employees = await this.$store.dispatch('users/find', { query: { tenantId:  this.$route.params.tenantId, role: {$ne: 'CUSTOMER' }, $client: { paginate: false } } })
      }
    }
  }
}
</script>