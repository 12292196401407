<template>
  <div>
    <div class="h-screen flex items-center lg:flex-nowrap flex-wrap">
      <div class="mx-auto max-w-7xl w-full text-center lg:text-left">
        <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16 items-center">
          <img
            v-if="appLogo"
            :src="appLogo"
            alt=""
            class="h-24 inline mb-10"
          >
          <h1 class="text-4xl tracking-tight text-gray-900">
            <span class="block">Sie haben sich erfolgreich bei uns <span class="text-primary"> registiert!</span></span>
          </h1>
          <p class="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            {{ appName }} kann nun aus einem App Store Ihrer Wahl heruntergeladen werden.
          </p>
          <div class="block md:flex justify-center lg:justify-start">
            <a
              v-if="appleLink"
              :href="appleLink"
              class="block text-center"
            >
              <img
                src="../assets/images/app-store-badge.svg"
                alt=""
                class="h-20 inline px-3 lg:px-0 py-3"
              >
            </a>
            <a
              v-if="googleLink"
              :href="googleLink"
              class="block text-center"
            >
              <img
                src="../assets/images/google-play-badge.svg"
                class="h-20 inline"
              >
            </a>
          </div>
        </div>
      </div>
      <div class="relative w-full h-72 sm:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <img
          class="absolute inset-0 w-full h-full object-cover"
          src="../assets/images/app-user.jpg"
          alt=""
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return{
      appName: 'myDocuments',
      googleLink: null,
      appleLink: null,
      appLogo: null,
      tenant: null
    }
  },
  computed:{

  },
  created(){
    this.fetchData()
  },
  methods:{
    async fetchData(){
      this.tenant = await this.$store.dispatch('tenants/get', this.$route.params.tenantId)
      this.googleLink = 'https://play.google.com/store/apps/details?id=de.mysolution.mydocuments.application&hl=de&gl=US'
      this.appleLink = 'https://apps.apple.com/de/app/mydocuments-vertragsmanager/id1370994319'
      this.appLogo = '../assets/images/mydocuments-logo.svg'
      if (this.tenant.app !== undefined) {
        this.appName = this.tenant.app.name
        this.googleLink = this.tenant.app.googleLink
        this.appleLink = this.tenant.app.appleLink
      }
      if (this.tenant.logoId !== null) {
        this.appLogo = this.tenant.logo.downloadUrl
      }
    }
  }
}

</script>
