<template>
  <div class="py-4 px-4 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
    <h1 class="text-2xl font-semibold text-gray-900 my-2">
      <slot />
    </h1>
    <div class="mt-3 flex sm:mt-0 sm:ml-4">
      <slot name="actions" />
    </div>
  </div>
</template>
<script>
export default {

}
</script>
