import store from '@/store/'
const publicPageNames = [
  'Login',
  'Register',
  'RegisterSuccess'
]

export default async function authenticationGuard (to, from, next) {
  const isLoggedIn = store.getters['auth/isAuthenticated']

  if (isLoggedIn) {
    return next()
  }

  try {
    await store.dispatch('auth/authenticate')
    next()
  } catch (exception) {
    const toPublicPage = publicPageNames.includes(to.name)
    if (toPublicPage) return next()
    console.error(exception)
    return next({ name: 'Login', query: { redirect: to.path, ...to.query } })
  }
}
