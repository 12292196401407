<template>
  <div class="flex flex-wrap content-center h-full">
    <div class="max-w-2xl mx-auto">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="card-title text-center">
          Anmelden
        </h3>
        <p class="text-center mb-5">
          Mit dem MyDocuments Konto anmelden
        </p>
        <template v-if="alerts.length > 0">
          <alert
            v-for="(alert, index) in alerts"
            :key="index"
            :type="alert.type"
            :icon="alert.icon"
            :message="alert.message"
            :link="alert.link"
            class="mt-3"
          />
        </template>

        <validate-form
          ref="loginForm"
          @submit="login"
        >
          <div class="mb-4">
            <label
              class="block text-grey-darker text-sm font-bold mb-2"
              for="username"
            >
              E-Mail
            </label>
            <input
              id="email"
              v-model="email"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              type="text"
            >
          </div>
          <div class="mb-2">
            <div class="flex mb-3">
              <BasicInput
                id="password1"
                v-model="password"
                class="input:rounded-none flex-grow"
                input-class="appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker border-r-0"
                type="password"
                label="Passwort"
                label-class="block text-grey-darker text-sm font-bold mb-2"
                show-button
                shadow
              />
            </div>
          </div>
          <button
            :disabled="loginPending"
            type="submit"
            class="btn btn-primary-light"
          >
            <span v-if="!loginPending">
              Anmelden
            </span>
            <i
              v-if="loginPending"
              class="fal fa-circle-notch fa-spin"
            />
          </button>
        </validate-form>
        <div class="text-center mt-5">
          &copy; {{ currentYear }} – mySolution OnlineApplicationService GmbH
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Alert from '@/components/Alert.vue'
import { Form as ValidateForm } from 'vee-validate'
import BasicInput from '../components/BasicInput.vue'

export default {
  name: 'Login',
  components: {
    Alert,
    ValidateForm,
    BasicInput
  },
  data () {
    return {
      email: '',
      password: '',
      loginPending: false,
      loginError: null,
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    alerts () {
      const alerts = []
      if (this.loginError) {
        switch (this.loginError.name) {
        case 'NotAuthenticated': {
          alerts.push({
            type: 'danger',
            icon: 'times',
            message: 'Benutzername oder Passwort falsch'
          })
          break
        }

        case 'NotAllowed': {
          alerts.push({
            type: 'danger',
            icon: 'times',
            message: 'Keine Berechtigung für diese Anwendung'
          })
          break
        }

        case 'Error': {
          alerts.push({
            type: 'danger',
            icon: 'times',
            message: 'Benutzername oder Passwort falsch'
          })
          break
        }

        default: {
          alerts.push({
            type: 'danger',
            icon: 'times',
            message: this.loginError.message
          })
        }
        }
      }
      return alerts
    }
  },
  async created () {
    // try authentication with jwt token
    try {
      const { user } = await this.$store.dispatch('auth/authenticate')
      if(user.role === 'DEVELOPER') this.$router.push(this.$route.query.redirect || { name: 'TenantList' })
      else this.$router.push(this.$route.query.redirect || { name: 'CustomerList' })
    } catch (e) {
      if (e.name !== 'NotAuthenticated') {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
  },
  methods: {
    async login () {
      try {
        const valid = await this.$refs.loginForm.validate()
        if (!valid) {
          return
        }
        this.loginPending = true
        const { user } = await this.$store.dispatch('auth/authenticate', {
          strategy: 'local',
          email: this.email,
          password: this.password,
          backoffice: true
        })
        if (!this.$store.state.tenantId) this.$store.state.tenantId = user.tenantId
        if(user.role === 'DEVELOPER') this.$router.push(this.$route.query.redirect || { name: 'TenantList' })
        else this.$router.push(this.$route.query.redirect || { name: 'CustomerList', params: { tenantId: this.$store.state.tenantId }})
      } catch (error) {
        console.error(error)
        this.loginError = error
      } finally {
        this.loginPending = false
      }
    }
  }
}
</script>

<style scoped>
.max-width {
    max-width: 500px;
}
</style>
