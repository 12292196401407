import { defineRule, configure } from 'vee-validate'
import { required, min_value, confirmed, max, url } from '@vee-validate/rules'
import { localize, setLocale  } from '@vee-validate/i18n'
import passwordStrength from './password-strength'
import passwordStrengthOptional from './password-strength-optional'
import disabledBirthdays from './disabled-birthdays'
import emailPattern from './email-pattern'
import emailPatternOptional from './email-pattern-optional'
import phonePatternOptional from './phone-pattern-optional'

import de from '@vee-validate/i18n/dist/locale/de.json'


defineRule('required', required)
defineRule('min_value', min_value)
defineRule('max', max)
defineRule('url', url)
defineRule('password-strength', passwordStrength)
defineRule('password-strength-optional', passwordStrengthOptional)
defineRule('confirmed', confirmed)
defineRule('disabled-birthdays', disabledBirthdays)
defineRule('email-pattern', emailPattern)
defineRule('email-pattern-optional', emailPatternOptional)
defineRule('phone-pattern-optional', phonePatternOptional)

configure({
  generateMessage: localize({ de })
})

setLocale('de')
