<template>
  <div>
    <layout-header>
      <template #default>
        Gesellschaften
      </template>
      <template #actions>
        <button
          type="button"
          class="ml-3 inline-flex items-center px-4 py-2 border border-green-600 rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          @click="$router.push({name: 'ContractCompaniesCreate'})"
        >
          Hinzufügen
        </button>
      </template>
    </layout-header>
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="name"
              @update:modelValue="fetchData"
            >
              Name
            </SortButton>
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <SortButton
              v-model="sortObj"
              name-in-mongo="vuNumber"
              @update:modelValue="fetchData"
            >
              VU Nummer
            </SortButton>
          </td>
          <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Logo
          </td>
          <td />
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr
          v-for="contractCompany of contractCompaniesList"
          :key="contractCompany._id"
        >
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ contractCompany.name }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ contractCompany.vuNumber }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <img
              v-if="contractCompany.logo"
              style="height:25px"
              :src="contractCompany.logo.downloadUrl"
            >
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <router-link
              class="btn btn-sm btn-success text-indigo-600 text-sm hover:text-indigo-700 btn-table"
              :to="{ name: 'ContractCompaniesUserDetail', params: { tenantId ,contractCompanyId: contractCompany._id }}"
            >
              Details
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import LayoutHeader from '../../components/LayoutHeader.vue'
import SortButton from '../../components/SortButton.vue'

export default {
  components: {
    LayoutHeader,
    // Pagination,
    SortButton
  },
  data(){
    return{
      contractCompaniesList: null,
      contracts: null,
      contractCompaniesIds: [],
      //SortButton
      sortObj: {
        sortBy: 'name', //propertyNameInMongo
        sortDirection: 1 // -1 = inverse
      }
    }
  },
  computed: {
    tenantId() {
      return this.$route.params.tenantId || this.$store.state.auth.user.tenantId
    },
  },
  watch: {
    $route: {
      handler: 'fetchData',
      immediate: true
    }
  },
  methods: {
    async fetchData () {
      this.contractCompaniesList = await this.$store.dispatch('contract-companies-tenant/find', {
        query: {
          $sort:{[this.sortObj.sortBy]: this.sortObj.sortDirection},
          tenantId: this.tenantId,
        }})
    },
  }
}
</script>
