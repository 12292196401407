<template>
  <div>
    <LayoutHeader>
      Individuelle Links
    </LayoutHeader>

    <Form @submit="submit">
      <div
        v-if="tenant != null"
        class="p-4"
      >
        <div class="grid grid-cols-2 gap-8">
          <div
            v-for="link, index in links"
            :key="index"
            class="grid gap-2 border rounded p-4"
          >
            <div class="flex justify-between">
              <h6>Link {{ index+1 }}</h6>
              <button
                type="button"
                class="text-red-500 text-sm"
                @click="removeLink(index)"
              >
                entfernen
              </button>
            </div>
            <BasicInput
              :id="`linkName${index}`"
              v-model="links[index].name"
              label="Anzeigename"
              label-class="block text-sm font-medium text-gray-700"
              input-class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              rules="required|max:60"
            />
            <BasicInput
              :id="`link${index}`"
              v-model="links[index].url"
              label="URL"
              type="url"
              label-class="block text-sm font-medium text-gray-700"
              input-class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              rules="required|url"
            />
            <BasicInput
              :id="`linkText${index}`"
              v-model="links[index].description"
              label="Beschreibung"
              label-class="block text-sm font-medium text-gray-700"
              input-class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              rules="max:60"
            />
          </div>
        </div>
        <div class="col-start-1 col-span-4 mt-2">
          <div class="flex justify-end">
            <input
              v-if="links.length < 5"
              type="button"
              value="Neuer Link"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-3"
              @click="addLink"
            >
            <input
              type="submit"
              value="Speichern"
              class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>
<script>
import BasicInput from '../components/BasicInput.vue'
import LayoutHeader from '../components/LayoutHeader.vue'
import { Form } from 'vee-validate'
export default {
  components: { LayoutHeader, BasicInput, Form },
  data(){
    return{
      tenant: null,
      links: []
    }
  },
  computed:{
  },
  created(){
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.tenant = await this.$store.dispatch('tenants/get', this.$route.params.tenantId)
      if(!('links' in this.tenant)){
        this.links = [{}]
      }else{
        this.links = this.tenant.links
      }
    },
    addLink(){
      this.links.push({})
    },
    async submit(){
      this.tenant.links = this.links
      await this.tenant.save()
    },
    removeLink(index){
      this.links.splice(index, 1)
    }
  }
}
</script>
