<template>
  <div>
    <layout-header>
      Makler erstellen
    </layout-header>
    <div
      class="p-4"
    >
      <TenantComponent />
    </div>
  </div>
</template>
<script>
import LayoutHeader from '../../components/LayoutHeader.vue'
import TenantComponent from './components/TenantComponent.vue'

export default {
  components: { LayoutHeader,  TenantComponent },
}
</script>
