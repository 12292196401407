<template>
  <div>
    <FaqEditModal
      ref="modal"
      :faq="editFaq"
      @close="fetchData()"
    />
    <layout-header>
      FAQ
    </layout-header>
    <div class="p-4">
      <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul class="divide-y divide-gray-200">
          <li
            v-for="faq in faqList"
            :key="faq._id"
          >
            <div class="block">
              <div class="flex items-center px-4 py-4 sm:px-6">
                <div class="min-w-0 flex-1 flex items-center">
                  <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <div class="text-gray-700">
                        <p>
                          {{ faq.question }}
                        </p>
                      </div>
                    </div>
                    <div class="hidden md:block">
                      <div
                        class="text-gray-700"
                      >
                        <div v-html="faq.answer" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    class="text-indigo-600 hover:text-indigo-900"
                    @click="show(faq)"
                  >
                    Bearbeiten
                  </button>
                  <button
                    class="ml-4 text-red-500"
                    type="button"
                    @click="deleteFAQ(faq._id)"
                  >
                    Löschen
                  </button>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div class="block">
              <Form
                class="items-center px-4 py-4 sm:px-6"
                @submit="addNewFaq"
              >
                <div class="min-w-0 flex-1 flex items-center">
                  <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
                    <div>
                      <div class="mt-1">
                        <BasicInput
                          id="question"
                          v-model="newFaq.question"
                          type="text"
                          name="question"
                          input-class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          label-class="block text-sm font-medium text-gray-700"
                          label="Frage"
                          rules="required"
                        />
                      </div>
                    </div>
                    <div class="hidden md:block">
                      <div class="mt-1">
                        <TextEditor
                          v-model="newFaq.answer"
                          label="Antwort"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end mt-2">
                  <button
                    type="submit"
                    class="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Hinzufügen
                  </button>
                </div>
              </Form>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import roles from '@/resources/enums/roles.js'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import { models } from '@/feathers-client'
import FaqEditModal from '@/components/modals/FaqEditModal'
import LayoutHeader from '../components/LayoutHeader.vue'
import BasicInput from '../components/BasicInput.vue'
import {Form} from 'vee-validate'
import TextEditor from '../components/TextEditor.vue'

const { api: { Faq }} = models

export default {
  components: {
    FaqEditModal,
    LayoutHeader,
    BasicInput,
    Form,
    TextEditor
  },
  data () {
    return {
      roles,
      dayjs,
      faqs: null,
      newFaq: null,
      editFaq: null,
      faqList: null
    }
  },
  watch:{
    '$route': {
      handler: 'fetchData',
      immediate: true
    }
  },
  created() {
    this.fetchData()
    this.newFaq = new Faq({ question: '', answer: '', tenantId: this.$route.params.tenantId })
  },
  methods: {
    show(faq){
      this.editFaq={...faq}
      this.$refs.modal.showModal({...faq})
    },
    async fetchData() {
      const FaqReq = await this.$store.dispatch('faq/find', {query: {tenantId: this.$route.params.tenantId}})
      this.faqList = FaqReq.data

    },
    async addNewFaq(value, {resetForm}) {
      await this.newFaq.save()
      resetForm()
      this.newFaq = new Faq({ question: '', answer: '', tenantId: this.$route.params.tenantId })
      this.fetchData()
    },
    async deleteFAQ(id){
      try {
        await this.$store.dispatch('faq/remove', [id])
        this.fetchData()
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
