<template>
  <div>
    <button
      class="focus:outline-none text-xs font-medium text-gray-500 uppercase tracking-wider"
      @click="changeValue"
    >
      <slot />
      <FontAwesomeIcon
        v-if="modelValue.sortBy !== nameInMongo"
        :icon="faSort"
      />
      <FontAwesomeIcon
        v-if="modelValue.sortBy === nameInMongo && modelValue.sortDirection === 1"
        :icon="faSortUp"
      />
      <FontAwesomeIcon
        v-if="modelValue.sortBy === nameInMongo && modelValue.sortDirection === -1"
        :icon="faSortDown"
      />
    </button>
  </div>
</template>
<script>
import { faSort, faSortUp, faSortDown } from '@fortawesome/pro-duotone-svg-icons'
export default {
  props:{
    nameInMongo:{
      type: String,
      required: true
    },
    modelValue:{
      type: Object,
      required: true
    }
  },
  emits:['update:modelValue'],
  data(){
    return{
      faSort,
      faSortUp,
      faSortDown,
      newDirection: 1
    }
  },
  methods:{
    changeValue(){
      if (this.modelValue.sortBy === this.nameInMongo) {
        this.newDirection = this.modelValue.sortDirection *(-1)
      }

      this.$emit('update:modelValue', {sortBy: this.nameInMongo, sortDirection: this.newDirection})
    }
  },
}
</script>
